import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { Icon, Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { MENU, PROJECT_ROUTE, PAGES_PERMISSION } from "../../constants/Common";
import { PAGE_PERMISSION } from "../../constants/PagePermission";

import { ReactComponent as Commission } from "../../assets/svg/commission.svg";
import { ReactComponent as TransactionDetail } from "../../assets/svg/transactiondetail.svg";
import { ReactComponent as Promotion } from "../../assets/svg/promotion.svg";
import { ReactComponent as StaticPage } from "../../assets/svg/static-page.svg";
import { ReactComponent as Rating } from "../../assets/svg/rating.svg";
import { ReactComponent as Scooter } from "../../assets/svg/scooter.svg";
import { ReactComponent as Price } from "../../assets/svg/price.svg";
import { ReactComponent as Dashboard } from "../../assets/svg/dashboard.svg";
import { ReactComponent as Dispute } from "../../assets/svg/dispute.svg";
import { ReactComponent as Notifications } from "../../assets/svg/notifications.svg";
import { ReactComponent as Payment } from "../../assets/svg/payment.svg";
import { ReactComponent as Vehicle } from "../../assets/svg/vehicleVolt.svg";
import { ReactComponent as Users } from "../../assets/svg/users.svg";
import { ReactComponent as Drivers } from "../../assets/svg/driver.svg";
import { ReactComponent as Attendance } from "../../assets/svg/attendance.svg";
import { ReactComponent as TimeSheet } from "../../assets/svg/time-sheet.svg";
import { ReactComponent as TimeSheetDetail } from "../../assets/svg/timesheet-detail.svg";
import { ReactComponent as RideDetail } from "../../assets/svg/ride-detail.svg";
import { ReactComponent as RideSummary } from "../../assets/svg/ride-summary.svg";
import { ReactComponent as RejectedRides } from "../../assets/svg/rejected-rides.svg";
import { ReactComponent as Version } from "../../assets/svg/version.svg";
import { ReactComponent as FOS } from "../../assets/svg/fos.svg";
import { ReactComponent as Tier } from "../../assets/svg/tier.svg";
import { ReactComponent as CashCollection } from "../../assets/svg/cash-collection.svg";
import { ReactComponent as FareCollection } from "../../assets/svg/fare-collection.svg";
import { ReactComponent as CommissionSummary } from "../../assets/svg/commission-summary.svg";
import { ReactComponent as CommissionDetail } from "../../assets/svg/commission.svg";
import { ReactComponent as Customer } from "../../assets/svg/customerVolt.svg";
import { ReactComponent as DeliveryHistory } from "../../assets/svg/deliveries.svg";
import { ReactComponent as MicroDeliveryHistory } from "../../assets/svg/deliveries.svg";
import { ReactComponent as DeliveryCollection } from "../../assets/svg/delivery-collection.svg";
import { ReactComponent as DailyReports } from "../../assets/svg/dailyReport.svg";
import { ReactComponent as CompanyDetails } from "../../assets/svg/company-details.svg";
import { ReactComponent as DeliveryPackages } from "../../assets/svg/delivery-packages.svg";
import { ReactComponent as MerchantDelivery } from "../../assets/svg/merchant-delivery-history.svg";
import { ReactComponent as RecurringHistory } from "../../assets/svg/recurring.svg";
import { ReactComponent as ScheduleHistory } from "../../assets/svg/schedule-ride.svg";
import { ReactComponent as MemberShip } from "../../assets/svg/membership.svg";

const _ = require("lodash");
class SidebarContent extends Component {
    components = {
        RideDetail: RideDetail,
        RideSummary: RideSummary,
        Vehicle: Vehicle,
        Dashboard: Dashboard,
        Dispute: Dispute,
        Notifications: Notifications,
        Payment: Payment,
        Users: Users,
        Drivers: Drivers,
        Attendance: Attendance,
        TimeSheet: TimeSheet,
        TimeSheetDetail: TimeSheetDetail,
        Commission: Commission,
        RejectedRides: RejectedRides,
        Version: Version,
        Price: Price,
        TransactionDetail: TransactionDetail,
        Promotion: Promotion,
        Rating: Rating,
        Scooter: Scooter,
        StaticPage: StaticPage,
        FOS: FOS,
        Tier: Tier,
        CashCollection: CashCollection,
        FareCollection: FareCollection,
        CommissionSummary: CommissionSummary,
        CommissionDetail: CommissionDetail,
        Customer: Customer,
        CustomerService : Customer,
        DeliveryHistory: DeliveryHistory,
        MicroDeliveryHistory:MicroDeliveryHistory,
        DeliveryCollection: DeliveryCollection,
        DailyReports: DailyReports,
        CompanyDetails: CompanyDetails,
        DeliveryPackages: DeliveryPackages,
        MerchantDelivery: MerchantDelivery,
        RecurringHistory: RecurringHistory,
        ScheduleHistory: ScheduleHistory,
        MemberShip: MemberShip
    };

    getNoHeaderClass = navStyle => {
        if (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
        ) {
            return "gx-no-header-notifications";
        }

        return "";
    };
    getNavStyleSubMenuClass = navStyle => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }

        return "";
    };

    getActiveMenuStyle = path => {
        const updatedPathArray = path.split("/");
        const updatedPath =
            "/" + PROJECT_ROUTE + "/" + updatedPathArray[2] + "/";
        let currentPath = window.location.pathname;
        const updatedCurrentPathArray = currentPath.split("/");

        const updatedCurrentPath =
            "/" + PROJECT_ROUTE + "/" + updatedCurrentPathArray[2] + "/";

        if (updatedCurrentPath.includes(updatedPath)) {
            return "active-menu-escooter";
        }
        return "";
    };

    returnSvg = svg => {
        const Tag = this.components[svg];
        return <Tag />;
    };

    render() {
        window.onpopstate = function (event) {
            // alert("location: " + + ", state: " + JSON.stringify(event.state));
        };
        const { pathname, navStyle, themeType, auth } = this.props;
        if (!auth || !auth.authUser) {
            return true;
        }

        let menuPermission =
            auth && auth.authUser && auth.authUser.accessPermission;
        const selectedKeys = this.props.location.pathname
        console.log('selectedKeys :>> ', selectedKeys);


        const defaultOpenKeys = selectedKeys.split("/")[1];
        console.log('defaultOpenKeys :>> ', defaultOpenKeys);


        // let loginUserType = 1;
        // if (auth && auth.authUser && auth.authUser.type) {
        //     loginUserType = auth.authUser.type;
        // }

        return (
            <Auxiliary>
                <SidebarLogo />
                <div className="gx-sidebar-content">
                    <div
                        className={`gx-sidebar-notifications ${this.getNoHeaderClass(
                            navStyle
                        )}`}
                    >
                        <UserProfile pathname={pathname} />
                        {/* <AppsNavigation /> */}
                    </div>
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <Menu
                            defaultOpenKeys={[defaultOpenKeys]}
                            selectedKeys={[selectedKeys]}
                            theme={
                                themeType === THEME_TYPE_LITE ? "lite" : "dark"
                            }
                            mode="inline"
                        >
                            {MENU.map((menu, index) => {
                                let indexes = _.findIndex(menuPermission, {
                                    module: menu.id
                                });
                                let hasPermission =
                                    menuPermission[indexes] &&
                                    menuPermission[indexes].permissions &&
                                    menuPermission[indexes].permissions.list;
                                return hasPermission ? (
                                    <Menu.Item
                                        key={menu.id}
                                        className={this.getActiveMenuStyle(
                                            menu.path
                                        )}
                                    >
                                        {menu.id ===
                                            PAGE_PERMISSION.VOLT_MOBILITY ? (
                                                <a
                                                    href="http://micromobility.voltmobility.net/e-scooter/dashboard"
                                                    target="_blank"
                                                >
                                                    {menu.svg ? (
                                                        <i className="anticon">
                                                            {" "}
                                                            {this.returnSvg(
                                                                menu.svg
                                                            )}  </i>
                                                    ) : (
                                                            <Icon type={menu.icon} />
                                                        )}
                                                    <span>{menu.name}</span>
                                                </a>
                                            ) : (
                                                <Link
                                                    to={menu.path ? menu.path : ""}
                                                >
                                                    {menu.svg ? (
                                                        <i className="anticon">
                                                            {" "}
                                                            {this.returnSvg(
                                                                menu.svg
                                                            )}
                                                        </i>
                                                    ) : (
                                                            <Icon type={menu.icon} />
                                                        )}
                                                    <span>{menu.name}</span>
                                                </Link>
                                            )}
                                    </Menu.Item>
                                ) : (
                                        ""
                                    );
                            })}
                        </Menu>
                    </CustomScrollbars>
                </div>
            </Auxiliary>
        );
    }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ auth, settings }) => {
    const { navStyle, themeType, locale, pathname } = settings;

    return { navStyle, themeType, locale, pathname, auth };
};

export default connect(mapStateToProps)(withRouter(SidebarContent));
